import { Page } from '~/cms/front/core/pages/Page'
import { SingleProductData } from '~/seo/front/core/structuredData/SingleProduct'
import component from './Product.vue'

class ProductPage extends Page {
	static alias = 'product'
	component = component
	product = {}

	constructor ({ app, route, from }) {
		super({ app, route, from })
		this.seo = new SingleProductData(app, { page: this })
	}

	async fetchData () {
		const Product = this.app.getEntity('product')
		this.product = await Product.fetchOne(this.alias)
		if (!this.product) return this.app.vue.$redirect({ path: '/404' })
		await this.setPageData(this.product.page)
	}

	get header () {
		return {
			title: this.value.header ? this.value.header : this.product.name
		}
	}

	restore () {
		super.restore()
		const Product = this.app.getEntity('product')
		this.product = Product.create(window.__PAGE__.product)
		this.value = window.__PAGE__.product.page.value
	}

	store () {
		const store = super.store()
		delete store.value
		store.product = this.product
		return store
	}

	afterRedirect () {
		this.app._emit('product:details', this.product)
	}
}

export { ProductPage }
