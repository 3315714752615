<template>
	<article class="product">
		<div class="hero-wrapper">
			<base-heading tag="h1" heading="header" subheading="subheader" />
		</div>
		<cms-multi-section value="content"/>
	</article>
</template>

<script>
import CmsMultiSection from '~/cms/front/components/CmsMultiSection'
import BaseHeading from '~/website/front/components/molecules/headings/BaseHeading/BaseHeading.vue'
import { pageMixin } from '~/website/front/core/pages/page.mixin'

export default {
	name: 'Product',
	components: {
		CmsMultiSection,
		BaseHeading
	},
	data: () => ({
		quantity: 1
	}),
	computed: {
		product () {
			return this.$app.page.product
		},
		value () {
			return this.$app.page.value
		},
		langs () {
			return this.$app.translator.get('products')
		}
	},
	methods: {
		setFieldFocus (state) {
			this.isFocus = state
		}
	},
	async prefetch () {
		await this.$app.translator.prefetch('products')
	},
	mixins: [
		pageMixin
	]
}
</script>
<style lang="scss" scoped>
.hero-wrapper {
	padding: 10rem 0 0 0;

	@include media-breakpoint-up(xl) {
		padding: 16rem 0 0 0;
	}
}
</style>
