import { Seo } from '~/cms/front/core/seo/Seo'

class SingleProductData extends Seo {
	get schemaJSON () {
		const string = this.page.product.description[this.app.language].replace(/(<([^>]+)>)/ig, '').slice(0, 120)

		const schema = {
			'@context': 'https://schema.org/',
			'@type': 'Product',
			name: this.page.product.name,
			image: [
				`${this.app.settings.env.origin}/api/images/${this.page.product.thumbnail.path}`
			],
			description: string,
			brand: {
				'@type': 'Brand',
				name: this.app.settings.general.contact.pageName
			},
			offers: {
				'@type': 'Offer',
				url: `${this.app.settings.env.origin}${this.page.route.fullPath}`,
				priceCurrency: 'PLN',
				price: this.price,
				availability: 'https://schema.org/InStock'
			}
		}

		return schema
	}
}

export { SingleProductData }
